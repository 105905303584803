.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  overflow-x: hidden;
  flex-direction: column;
}
.home-hero {
  width: 100%;
  height: 1000px;
  display: flex;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  padding-left: var(--dl-space-space-sevenunits);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  background-color: #f59328;
}
.home-menu {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-mobile-navigation {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 150;
  position: fixed;
  transform: translateX(100%);
  transition: transform 0.3s;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.home-logo {
  width: 64px;
}
.home-links {
  gap: var(--dl-space-space-twounits);
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  flex-direction: column;
}
.home-text {
  text-decoration: none;
}
.home-link {
  text-decoration: none;
}
.home-link01 {
  text-decoration: none;
}
.home-close-mobile-menu {
  top: var(--dl-space-space-oneandhalfunits);
  right: var(--dl-space-space-oneandhalfunits);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-icon {
  width: 24px;
  height: 24px;
  display: flex;
}
.home-desktop-navigation {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  flex-direction: column;
}
.home-centered {
  width: 100%;
  display: flex;
  max-width: 1200px;
  box-sizing: initial;
  align-items: center;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  justify-content: space-between;
  background-color: #ffffff;
}
.home-left {
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 38px;
  flex-direction: row;
  padding-bottom: 38px;
}
.home-link02 {
  display: contents;
}
.home-logo1 {
  width: 139px;
  height: 43px;
  margin-left: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.home-links1 {
  display: flex;
  align-items: center;
  margin-left: 33px;
  flex-direction: row;
}
.home-link03 {
  text-decoration: none;
}
.home-link04 {
  text-decoration: none;
}
.home-link05 {
  text-decoration: none;
}
.home-right {
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-sign-in {
  margin-right: 20px;
  text-decoration: none;
}
.home-get-started {
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 56px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  background-color: #000000;
}
.home-get-started:hover {
  background-color: #5228F5;
}
.home-link06 {
  color: #ffffff;
  font-style: normal;
  font-weight: 600;
  text-decoration: none;
}
.home-burger-menu {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-mobile-menu-button {
  width: 40px;
  height: 40px;
  display: none;
}
.home-container02 {
  display: contents;
}
.home-header {
  display: flex;
  margin-top: 140px;
  align-items: center;
  flex-direction: column;
}
.home-text01 {
  color: var(--dl-color-gray-white);
  font-size: 72px;
  font-style: normal;
  text-align: center;
  font-weight: 700;
  line-height: 80px;
}
.home-text03 {
  color: #000000;
}
.home-text05 {
  color: rgb(0, 0, 0);
  font-size: 20px;
  max-width: 600px;
  margin-top: 16px;
  text-align: center;
  font-family: "Georgia";
  line-height: 30px;
  text-transform: capitalize;
}
.home-link07 {
  display: contents;
}
.home-get-started1 {
  color: white;
  cursor: pointer;
  display: flex;
  margin-top: 40px;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 56px;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: #000000;
}
.home-get-started1:hover {
  color: black;
  background-color: #ffffff;
}
.home-text06 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.home-dashboard-preview {
  top: 650px;
  display: flex;
  position: absolute;
  align-items: center;
  border-color: #f6ab3d;
  border-width: 30px;
  border-radius: 100px;
  flex-direction: column;
  background-color: #f69e3d;
}
.home-outline {
  display: flex;
  align-items: center;
  border-color: #f78e50;
  border-width: 20px;
  border-radius: 70px;
  flex-direction: column;
  background-color: #f7ba50;
}
.home-image {
  width: 900px;
  z-index: 10;
  user-select: none;
}
.home-features {
  width: 100%;
  display: flex;
  z-index: 1;
  align-items: center;
  padding-top: 400px;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  background-color: EFF0F2;
}
.home-title {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text07 {
  font-size: 56px;
  font-style: normal;
  text-align: center;
  font-weight: 700;
  line-height: 72px;
}
.home-text09 {
  color: #000000;
}
.home-text13 {
  font-size: 20px;
  margin-top: 16px;
  text-align: center;
  line-height: 30px;
}
.home-cards {
  width: 100%;
  display: flex;
  max-width: 1200px;
  margin-top: 80px;
  align-items: center;
  flex-direction: column;
}
.home-container03 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.home-icon2 {
  width: 32px;
  height: 30px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text14 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text15 {
  line-height: 24px;
}
.home-publish {
  height: initial;
}
.home-icon3 {
  width: 30px;
  height: 30px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text16 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text17 {
  line-height: 24px;
}
.home-container04 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-sixunits);
  flex-direction: row;
}
.home-icon4 {
  width: 35px;
  height: 30px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text18 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: 16px;
}
.home-text19 {
  line-height: 24px;
}
.home-icon5 {
  width: 35px;
  height: 30px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text20 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: 16px;
}
.home-text21 {
  line-height: 24px;
}
.home-quote-container {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 160px;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  padding-bottom: 160px;
  justify-content: center;
  background-color: #000000;
}
.home-quote {
  width: 100%;
  display: flex;
  max-width: 1200px;
  flex-direction: column;
  justify-content: center;
}
.home-message {
  color: rgb(255, 255, 255);
  font-size: 40px;
  max-width: 800px;
  line-height: 52px;
}
.home-author {
  width: 800px;
  display: flex;
  margin-top: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  flex-direction: row;
}
.home-avatar {
  width: 64px;
  height: 64px;
  object-fit: cover;
  border-radius: 50%;
}
.home-quote1 {
  color: rgb(151, 126, 249);
  width: 100%;
  max-width: 800px;
  margin-left: var(--dl-space-space-unit);
}
.home-text22 {
  font-style: normal;
  font-weight: 600;
}
.home-banners {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-banner-manage {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
}
.home-container05 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-left1 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.home-text24 {
  color: #f59328;
}
.home-text25 {
  text-align: left;
}
.home-text26 {
  color: rgb(0, 0, 0);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-link08 {
  display: contents;
}
.home-get-started2 {
  transition: 0.3s;
  margin-bottom: 0;
  text-decoration: none;
}
.home-get-started2:hover {
  color: var(--dl-color-gray-white);
  background-color: var(--dl-color-template-blue-bg);
}
.home-text27 {
  font-size: 18px;
}
.home-image-container {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-cards-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  user-select: none;
}
.home-banner-advanced-analytics {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
  background-color: #EFF0F2;
}
.home-centered-container {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  justify-content: center;
}
.home-image-container1 {
  flex: 1;
  display: flex;
}
.home-cards-image1 {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.home-right1 {
  flex: 1;
  display: flex;
  margin-left: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.home-text28 {
  color: #f59328;
}
.home-text29 {
  text-align: left;
}
.home-category {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
}
.home-text30 {
  color: rgb(0, 0, 0);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text31 {
  color: rgb(0, 0, 0);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-link09 {
  display: contents;
}
.home-get-started3 {
  transition: 0.3s;
  margin-bottom: 0;
  text-decoration: none;
}
.home-get-started3:hover {
  color: var(--dl-color-gray-white);
  background-color: var(--dl-color-template-blue-bg);
}
.home-text32 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.home-banner {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
}
.home-container06 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-left2 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.home-text33 {
  color: #f59328;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 24px;
}
.home-text34 {
  text-align: left;
}
.home-text35 {
  color: rgb(0, 0, 0);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-link10 {
  display: contents;
}
.home-get-started4 {
  transition: 0.3s;
  margin-bottom: 0;
  text-decoration: none;
}
.home-get-started4:hover {
  color: var(--dl-color-gray-white);
  background-color: var(--dl-color-template-blue-bg);
}
.home-text36 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.home-image-container2 {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-cards-image2 {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.home-integrations {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-sevenunits);
  align-items: center;
  padding-top: 112px;
  flex-direction: column;
  justify-content: center;
  background-color: #EFF0F2;
}
.home-centered-container1 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-heading {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text37 {
  color: #f59328;
}
.home-text39 {
  color: rgb(0, 0, 0);
  text-align: center;
  margin-bottom: var(--dl-space-space-fiveunits);
}
.home-pills-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-pills {
  width: 100%;
  display: flex;
  grid-gap: var(--dl-space-space-twounits);
  flex-wrap: wrap;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: row;
  justify-content: center;
}
.home-container07 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}
.home-container08 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  flex-wrap: wrap;
  margin-left: var(--dl-space-space-fiveunits);
  flex-direction: row;
  justify-content: center;
}
.home-how-it-works {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  background-color: #f59328;
}
.home-centered-container2 {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 1200px;
  align-items: flex-end;
  flex-direction: column;
  justify-content: space-between;
}
.home-heading1 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.home-text40 {
  color: var(--dl-color-gray-black);
  font-style: normal;
  font-weight: 600;
  margin-bottom: 24px;
}
.home-text41 {
  color: var(--dl-color-gray-white);
}
.home-category1 {
  color: white;
  width: 100%;
  display: flex;
  overflow: hidden;
  padding-left: var(--dl-space-space-fourunits);
  border-radius: 50px;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: row;
  background-color: #FFA500;
}
.home-headng {
  flex: 1;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  margin-right: var(--dl-space-space-fourunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
}
.home-text42 {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 16px;
}
.home-text43 {
  color: var(--dl-color-gray-black);
  line-height: 24px;
  margin-bottom: 40px;
}
.home-link11 {
  display: contents;
}
.home-get-started5 {
  margin-bottom: 0px;
  text-decoration: none;
}
.home-get-started5:hover {
  color: black;
  background-color: #ffffff;
}
.home-text44 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.home-container09 {
  flex: 1;
  height: 100%;
  display: flex;
  align-self: flex-end;
  flex-direction: row;
}
.home-pasted-image {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.home-row {
  display: flex;
  grid-gap: var(--dl-space-space-threeunits);
  align-items: center;
  flex-direction: row;
}
.home-category2 {
  flex: 1;
  color: white;
  height: 555px;
  display: flex;
  overflow: hidden;
  align-items: flex-end;
  border-radius: 50px;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
}
.home-headng1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: column;
  padding-bottom: 0px;
}
.home-text45 {
  color: rgb(0, 0, 0);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 16px;
}
.home-text46 {
  color: rgb(30, 30, 30);
}
.home-pasted-image1 {
  width: 100%;
}
.home-category3 {
  flex: 1;
  color: white;
  height: 555px;
  display: flex;
  overflow: hidden;
  align-items: flex-end;
  border-radius: 50px;
  flex-direction: column;
  justify-content: space-between;
  background-color: #000000;
}
.home-headng2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: column;
  padding-bottom: 0px;
}
.home-text47 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 16px;
}
.home-text48 {
  color: rgb(238, 233, 254);
}
.home-pasted-image2 {
  width: 100%;
  object-fit: contain;
}
.home-pricing {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
  background-color: #f59328;
}
.home-action-bar {
  width: 100%;
  display: flex;
  padding: 120px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-action {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: flex-end;
  border-radius: 50px;
  flex-direction: row;
  justify-content: space-between;
  background-color: #f59328;
}
.home-heading2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-sevenunits);
  padding-left: var(--dl-space-space-sevenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sevenunits);
}
.home-text49 {
  color: var(--dl-color-gray-white);
  text-align: left;
}
.home-text53 {
  color: var(--dl-color-gray-black);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-link12 {
  display: contents;
}
.home-get-started6 {
  margin-bottom: 0px;
  text-decoration: none;
}
.home-text54 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.home-images {
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: space-between;
}
.home-image1 {
  width: 100%;
  object-fit: cover;
  user-select: none;
}
.home-footer {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sevenunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sevenunits);
  background-color: #000000;
}
.home-top {
  width: 100%;
  display: flex;
  max-width: 1200px;
  border-color: #3B3B3B;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-left3 {
  width: 33%;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-twounits);
  flex-direction: column;
}
.home-text57 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-subscription {
  width: 100%;
  height: 66px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 50px;
  margin-bottom: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: space-between;
  background-color: #ffffff;
}
.home-textinput {
  width: 60%;
  outline: none;
  font-size: 14px;
  border-width: 0px;
}
.home-subscribe {
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: 16px;
  padding-left: 32px;
  border-radius: 56px;
  padding-right: 32px;
  flex-direction: row;
  padding-bottom: 16px;
  justify-content: flex-start;
  background-color: #f59328;
}
.home-subscribe:hover {
  background-color: #000;
}
.home-text58 {
  color: #ffffff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}
.home-text59 {
  color: rgb(104, 104, 104);
}
.home-link13 {
  text-decoration: underline;
}
.home-contact {
  display: flex;
  margin-top: 52px;
  align-items: flex-start;
  flex-direction: column;
}
.home-text62 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
}
.home-link14 {
  color: rgb(196, 196, 196);
}
.home-container10 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.home-container11 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.home-container12 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container13 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container14 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-text63 {
  color: var(--dl-color-gray-white);
}
.home-link15 {
  text-decoration: underline;
}
.home-link16 {
  text-decoration: underline;
}
.home-link17 {
  text-decoration: underline;
}
.home-link18 {
  display: contents;
}
.home-image2 {
  width: 150px;
  height: 55px;
  align-self: flex-start;
  object-fit: cover;
  padding-left: 4px;
  text-decoration: none;
}
@media(max-width: 991px) {
  .home-hero {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    background-color: #f59b28;
  }
  .home-close-mobile-menu {
    align-items: center;
    justify-content: center;
  }
  .home-desktop-navigation {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-centered {
    height: 60px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .home-left {
    margin-left: var(--dl-space-space-halfunit);
  }
  .home-logo1 {
    width: 124px;
    margin-right: 0px;
  }
  .home-links1 {
    display: none;
  }
  .home-sign-in {
    display: none;
  }
  .home-get-started {
    display: none;
  }
  .home-mobile-menu-button {
    display: flex;
  }
  .home-header {
    width: 100%;
    max-width: 1200px;
  }
  .home-dashboard-preview {
    border-color: #f6ab3d;
    background-color: #f6903d;
  }
  .home-outline {
    border-color: #f7c050;
    background-color: #f7a750;
  }
  .home-image {
    width: 100%;
  }
  .home-features {
    padding-top: 260px;
  }
  .home-title {
    width: 100%;
    max-width: 1200px;
  }
  .home-text07 {
    font-size: 36px;
    line-height: 40px;
  }
  .home-text13 {
    font-size: 20px;
    margin-top: var(--dl-space-space-unit);
    line-height: 30px;
  }
  .home-cards {
    margin-top: var(--dl-space-space-fiveunits);
  }
  .home-text15 {
    line-height: 21px;
  }
  .home-text17 {
    line-height: 21px;
  }
  .home-text19 {
    line-height: 21px;
  }
  .home-text21 {
    line-height: 21px;
  }
  .home-quote-container {
    padding-top: var(--dl-space-space-sevenunits);
    padding-bottom: var(--dl-space-space-sevenunits);
  }
  .home-message {
    width: 100%;
    max-width: 800px;
  }
  .home-author {
    width: 100%;
    max-width: 800px;
  }
  .home-banner-manage {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-left1 {
    margin-right: var(--dl-space-space-threeunits);
  }
  .home-cards-image {
    width: 360px;
  }
  .home-banner-advanced-analytics {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-container08 {
    margin-left: 0px;
  }
  .home-category1 {
    padding-left: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .home-headng {
    margin-left: var(--dl-space-space-fourunits);
  }
  .home-container09 {
    width: 100%;
  }
  .home-pasted-image {
    height: auto;
  }
  .home-headng1 {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
  }
  .home-headng2 {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
  }
  .home-action {
    flex-direction: column;
  }
  .home-heading2 {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-text49 {
    font-size: 36px;
    line-height: 36px;
  }
  .home-footer {
    padding: var(--dl-space-space-sixunits);
  }
  .home-top {
    flex-direction: column;
  }
  .home-left3 {
    width: 100%;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-text59 {
    color: rgb(104, 104, 104);
    font-size: 14px;
  }
  .home-text62 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-link14 {
    text-decoration: underline none;
  }
  .home-text63 {
    color: var(--dl-color-gray-white);
    font-size: 12px;
    line-height: 25px;
    margin-bottom: 0px;
  }
  .home-link15 {
    text-decoration: underline;
  }
  .home-link16 {
    text-decoration: underline;
  }
  .home-link17 {
    text-decoration: underline;
  }
}
@media(max-width: 767px) {
  .home-hero {
    height: 620px;
    position: relative;
    padding-bottom: 180px;
    background-color: #f58b28;
  }
  .home-desktop-navigation {
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-centered {
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-left {
    margin-left: var(--dl-space-space-unit);
  }
  .home-logo1 {
    width: 121px;
  }
  .home-right {
    margin-right: var(--dl-space-space-unit);
  }
  .home-sign-in {
    display: none;
    text-decoration: none;
  }
  .home-get-started {
    display: none;
  }
  .home-link06 {
    color: rgb(255, 255, 255);
    font-style: normal;
    font-weight: 600;
    text-decoration: none;
  }
  .home-header {
    height: 100%;
    margin-top: var(--dl-space-space-fourunits);
    justify-content: center;
  }
  .home-text01 {
    color: rgb(255, 255, 255);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
  }
  .home-text05 {
    color: var(--dl-color-gray-black);
    font-size: 16px;
    margin-top: var(--dl-space-space-unit);
    font-family: Inter;
    font-weight: 700;
    line-height: 24px;
    text-transform: none;
    text-decoration: none;
  }
  .home-get-started1 {
    margin-top: var(--dl-space-space-twounits);
  }
  .home-dashboard-preview {
    top: 520px;
    width: 90%;
    border-color: #f6ab3d;
    border-width: 10px;
    border-radius: 45px;
    background-color: #f69e3d;
  }
  .home-outline {
    border-color: #f7b450;
    border-width: 10px;
    border-radius: 35px;
    background-color: #f7a150;
  }
  .home-image {
    width: 100%;
  }
  .home-features {
    padding-top: 350px;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-text07 {
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
  }
  .home-text13 {
    font-size: 20px;
    line-height: 30px;
  }
  .home-container03 {
    gap: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .home-text14 {
    font-size: 24px;
  }
  .home-text15 {
    font-size: 14px;
  }
  .home-publish {
    margin-left: 0px;
  }
  .home-text16 {
    font-size: 24px;
  }
  .home-text17 {
    font-size: 14px;
  }
  .home-container04 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .home-analyze {
    margin-right: 0px;
  }
  .home-text18 {
    font-size: 24px;
  }
  .home-text19 {
    font-size: 14px;
  }
  .home-analyze1 {
    margin-right: 0px;
  }
  .home-text20 {
    font-size: 24px;
  }
  .home-text21 {
    font-size: 14px;
  }
  .home-quote-container {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-message {
    font-size: 24px;
    line-height: 31px;
  }
  .home-author {
    width: 100%;
  }
  .home-avatar {
    width: 40px;
    height: 40px;
  }
  .home-quote1 {
    font-size: 14px;
    max-width: 800px;
  }
  .home-banner-manage {
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-container05 {
    flex-direction: column;
  }
  .home-left1 {
    margin-right: 0px;
  }
  .home-text25 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text26 {
    line-height: 24px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-text27 {
    font-size: 16px;
  }
  .home-image-container {
    justify-content: center;
  }
  .home-cards-image {
    margin-top: var(--dl-space-space-threeunits);
  }
  .home-banner-advanced-analytics {
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-centered-container {
    flex-direction: column-reverse;
  }
  .home-right1 {
    margin-left: 0px;
  }
  .home-text29 {
    font-size: 28px;
    text-align: left;
    line-height: 26px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-text30 {
    font-size: 20px;
  }
  .home-text31 {
    line-height: 24px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text32 {
    font-size: 16px;
  }
  .home-banner {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-container06 {
    flex-direction: column;
  }
  .home-left2 {
    margin-right: 0px;
  }
  .home-integrations {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-text39 {
    line-height: 24px;
  }
  .home-pills {
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-how-it-works {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
    background-color: #f58b28;
  }
  .home-heading1 {
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-text41 {
    margin-bottom: 0px;
  }
  .home-headng {
    border-color: #FFA500;
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius4);
    padding-bottom: 0px;
  }
  .home-get-started5 {
    display: none;
  }
  .home-container09 {
    width: 100%;
  }
  .home-row {
    height: auto;
    flex-direction: column;
  }
  .home-pricing {
    width: 100%;
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
  }
  .home-action-bar {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-action {
    align-items: center;
  }
  .home-heading2 {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-text49 {
    font-size: 28px;
    text-align: left;
  }
  .home-text54 {
    color: var(--dl-color-gray-white);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
  }
  .home-images {
    justify-content: center;
  }
  .home-image1 {
    width: 100%;
  }
  .home-footer {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text63 {
    color: var(--dl-color-gray-white);
  }
  .home-link15 {
    text-decoration: underline;
  }
  .home-link16 {
    text-decoration: underline;
  }
  .home-link17 {
    text-decoration: underline;
  }
}
@media(max-width: 479px) {
  .home-hero {
    padding-bottom: 160px;
  }
  .home-logo {
    margin-left: 0px;
  }
  .home-icon {
    align-items: center;
    justify-content: center;
  }
  .home-logo1 {
    width: 110px;
  }
  .home-sign-in {
    text-decoration: none;
  }
  .home-link06 {
    color: rgb(255, 255, 255);
  }
  .home-mobile-menu-button {
    padding-bottom: 0px;
  }
  .home-text05 {
    color: var(--dl-color-gray-black);
    font-size: 20px;
    line-height: 30px;
  }
  .home-get-started1 {
    width: 100%;
  }
  .home-dashboard-preview {
    top: 500px;
  }
  .home-features {
    padding-top: 200px;
  }
  .home-cards {
    margin-top: var(--dl-space-space-threeunits);
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-container03 {
    gap: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-container04 {
    gap: var(--dl-space-space-oneandhalfunits);
    margin-bottom: 0px;
  }
  .home-analyze {
    margin-bottom: 0px;
  }
  .home-text19 {
    font-size: 14px;
    line-height: 24px;
  }
  .home-analyze1 {
    margin-bottom: 0px;
  }
  .home-text20 {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 33px;
  }
  .home-text21 {
    font-size: 14px;
    line-height: 24px;
  }
  .home-text26 {
    line-height: 24px;
  }
  .home-cards-image {
    width: 100%;
    margin-top: var(--dl-space-space-twounits);
  }
  .home-how-it-works {
    background-color: #f58b28;
  }
  .home-category1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .home-headng {
    border-color: #FFA500;
  }
  .home-pricing {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-action-bar {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-footer {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-subscription {
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .home-text63 {
    color: var(--dl-color-gray-white);
  }
  .home-image2 {
    width: 156px;
    height: 59px;
    padding-bottom: 0px;
  }
}
