.centered-container3-centered-container {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1200px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.centered-container3-heading {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.centered-container3-text {
  color: var(--dl-color-gray-white);
  text-align: center;
}
.centered-container3-text01 {
  color: var(--dl-color-gray-black);
  width: 600px;
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.centered-container3-selection {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.centered-container3-text02 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  width: 128px;
  font-style: normal;
  text-align: center;
  transition: 0.3s;
  font-weight: 600;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: var(--dl-color-gray-white);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 2px;
}
.centered-container3-text02:hover {
  fill: var(--dl-color-template-blue-bg);
  color: var(--dl-color-template-blue-bg);
}
.centered-container3-text03 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  width: 128px;
  font-style: normal;
  text-align: center;
  transition: 0.3s;
  font-weight: 600;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: var(--dl-color-gray-black);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  background-color: var(--dl-color-gray-white);
  border-bottom-width: 2px;
}

.centered-container3-cards {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  margin-top: var(--dl-space-space-fiveunits);
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  grid-template-columns: 1fr 1fr 1fr;
}
.centered-container3-card {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-threeunits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
  background-color: #ffffff;
}
.centered-container3-text04 {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.centered-container3-text05 {
  color: rgb(0, 0, 0);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-fourunits);
}
.centered-container3-text07 {
  text-decoration: line-through;
}
.centered-container3-text08 {
  text-decoration: line-through;
}
.centered-container3-text11 {
  color: rgb(0, 0, 0);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-fourunits);
}
.centered-container3-link {
  display: contents;
}
.centered-container3-get-started {
  transition: 0.3s;
  text-decoration: none;
}
.centered-container3-get-started:hover {
  color: var(--dl-color-gray-white);
  background-color: var(--dl-color-template-blue-bg);
}
.centered-container3-text14 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.centered-container3-text15 {
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.centered-container3-bullet-points {
  width: 206px;
  height: 169px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.centered-container3-point {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.centered-container3-icon {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.centered-container3-text16 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: var(--dl-space-space-halfunit);
}
.centered-container3-point01 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.centered-container3-icon02 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.centered-container3-text19 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: var(--dl-space-space-halfunit);
}
.centered-container3-point02 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.centered-container3-icon04 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.centered-container3-text22 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: var(--dl-space-space-halfunit);
}
.centered-container3-point03 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.centered-container3-icon06 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.centered-container3-text25 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: var(--dl-space-space-halfunit);
}
.centered-container3-point04 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.centered-container3-icon08 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.centered-container3-text28 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: var(--dl-space-space-halfunit);
}
.centered-container3-point05 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.centered-container3-icon10 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.centered-container3-text31 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: var(--dl-space-space-halfunit);
}
.centered-container3-card1 {
  flex: 1;
  height: 864px;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: 26px;
  border-color: #ffa500;
  border-width: 1px;
  padding-left: 40px;
  border-radius: 50px;
  padding-right: 40px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
  background-color: #FFA500;
}
.centered-container3-text34 {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.centered-container3-text37 {
  color: rgb(0, 0, 0);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-fourunits);
}
.centered-container3-text41 {
  text-decoration: line-through;
}
.centered-container3-text44 {
  color: rgb(0, 0, 0);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-fourunits);
}
.centered-container3-link1 {
  display: contents;
}
.centered-container3-get-started1 {
  transition: 0.3s;
  text-decoration: none;
}
.centered-container3-get-started1:hover {
  color: var(--dl-color-gray-white);
  background-color: var(--dl-color-template-blue-bg);
}
.centered-container3-text48 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.centered-container3-text51 {
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.centered-container3-bullet-points1 {
  width: 206px;
  height: 169px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.centered-container3-point06 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.centered-container3-icon12 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.centered-container3-text52 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: var(--dl-space-space-halfunit);
}
.centered-container3-point07 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.centered-container3-icon14 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.centered-container3-text55 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: var(--dl-space-space-halfunit);
}
.centered-container3-point08 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.centered-container3-icon16 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.centered-container3-text58 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: var(--dl-space-space-halfunit);
}
.centered-container3-point09 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.centered-container3-icon18 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.centered-container3-text61 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: var(--dl-space-space-halfunit);
}
.centered-container3-point10 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.centered-container3-icon20 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.centered-container3-text64 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: var(--dl-space-space-halfunit);
}
.centered-container3-point11 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.centered-container3-icon22 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.centered-container3-text67 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: var(--dl-space-space-halfunit);
}
.centered-container3-card2 {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: 40px;
  border-radius: 50px;
  padding-right: 40px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
  background-color: #000000;
}
.centered-container3-text70 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.centered-container3-text71 {
  color: rgb(255, 255, 255);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-fourunits);
}
.centered-container3-text73 {
  text-decoration: line-through;
}
.centered-container3-text74 {
  text-decoration: line-through;
}
.centered-container3-text77 {
  color: rgb(255, 255, 255);
  line-height: 24px;
  margin-bottom: var(--dl-space-space-fourunits);
}
.centered-container3-link2 {
  display: contents;
}
.centered-container3-get-started2 {
  color: var(--dl-color-gray-black);
  transition: 0.3s;
  text-decoration: none;
  background-color: #ffffff;
}
.centered-container3-get-started2:hover {
  color: var(--dl-color-gray-white);
  background-color: var(--dl-color-template-blue-bg);
}
.centered-container3-text78 {
  font-size: 18px;
}
.centered-container3-text81 {
  color: rgb(255, 255, 255);
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.centered-container3-bullet-points2 {
  width: 205px;
  height: 212px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.centered-container3-point12 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.centered-container3-icon24 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.centered-container3-text82 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: var(--dl-space-space-halfunit);
}
.centered-container3-text83 {
  color: var(--dl-color-gray-white);
}
.centered-container3-point13 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.centered-container3-icon26 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.centered-container3-text85 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: var(--dl-space-space-halfunit);
}
.centered-container3-text86 {
  color: var(--dl-color-gray-white);
}
.centered-container3-point14 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.centered-container3-icon28 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.centered-container3-text88 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: var(--dl-space-space-halfunit);
}
.centered-container3-point15 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.centered-container3-icon30 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.centered-container3-text89 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: var(--dl-space-space-halfunit);
}
.centered-container3-text90 {
  color: var(--dl-color-gray-white);
}
.centered-container3-point16 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.centered-container3-icon32 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.centered-container3-text92 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: var(--dl-space-space-halfunit);
}
.centered-container3-point17 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
}
.centered-container3-icon34 {
  fill: #5228F5;
  width: 8px;
  height: 8px;
}
.centered-container3-text95 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: var(--dl-space-space-halfunit);
}
.centered-container3-text96 {
  color: var(--dl-color-gray-white);
}
@media(max-width: 991px) {
  .centered-container3-text01 {
    width: 100%;
    max-width: 600px;
  }
  .centered-container3-cards {
    display: flex;
    flex-direction: column;
  }
  .centered-container3-card {
    width: 100%;
    align-items: flex-start;
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .centered-container3-text05 {
    font-size: 16px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .centered-container3-text06 {
    font-size: 16px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .centered-container3-text07 {
    font-size: 16px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .centered-container3-text08 {
    font-size: 16px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .centered-container3-text09 {
    font-size: 16px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .centered-container3-text11 {
    font-size: 16px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .centered-container3-text12 {
    font-size: 16px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .centered-container3-text15 {
    font-size: 16px;
  }
  .centered-container3-card1 {
    width: 100%;
    align-items: flex-start;
  }
  .centered-container3-text51 {
    font-size: 16px;
  }
  .centered-container3-card2 {
    width: 100%;
    align-items: flex-start;
  }
}
@media(max-width: 767px) {
  .centered-container3-card {
    padding-bottom: var(--dl-space-space-twounits);
  }
  .centered-container3-text04 {
    font-size: 28px;
  }
  .centered-container3-card1 {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .centered-container3-text34 {
    font-size: 28px;
  }
  .centered-container3-card2 {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .centered-container3-text70 {
    font-size: 28px;
  }
  .centered-container3-bullet-points2 {
    width: 211px;
    height: 224px;
  }
}
@media(max-width: 479px) {
  .centered-container3-get-started2 {
    background-color: var(--dl-color-gray-white);
  }
  .centered-container3-bullet-points2 {
    width: 211px;
    height: 247px;
  }
  .centered-container3-text82 {
    font-size: 14px;
  }
  .centered-container3-text83 {
    color: var(--dl-color-gray-white);
  }
}
